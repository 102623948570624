import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { CrudService } from './crud.service';
import { environment } from 'src/environments/environment';
import { Login } from '../models/login.model';
import { Tokens } from '../models/tokens.model';
import { Router } from '@angular/router';
import { ApiService } from './api.service';

interface httpResponseCode {
  _httpResponseCode: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CrudService {
  private _loggedUser: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private api: ApiService
  ) {
    super(http);
  }

  get url(): string {
    return `${environment.apiUrl}`;
  }

  login(data: Login): Observable<any> {
    return this.http
      .post<any>(`${this.url}/login`, data, {
        headers: this.httpOptions.headers,
        observe: 'response' as 'body',
        responseType: 'json'
      })
      .pipe(
        tap((response) => {
          this.doLoginUser(response.body);
        }),
        mapTo(true),
        catchError((error) => {
          return of(false);
        })
      );
  }

  register(data: any): Observable<any> {
    return this.http
      .post<Login>(`${this.url}/signUp`, data, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  resetPassword(email: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post(
        `${this.url}/requestPasswordReset`,
        { email: email },
        { headers, responseType: 'text' }
      )
      .pipe(catchError(this.errorHandler));
  }

  changePassword(password: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post(
        `${this.url}/requestPasswordReset`,
        { password: password },
        { headers, responseType: 'text' }
      )
      .pipe(catchError(this.errorHandler));
  }

  logout() {
    return this.http.post<any>(`${this.url}/logout`, {}).pipe(
      mapTo(true),
      catchError((error) => {
        return of(false);
      })
    );
  }

  refreshToken() {
    return this.http
      .post<httpResponseCode>(
        `${this.url}/refreshToken`,
        {},
        { observe: 'response' }
      )
      .pipe(map((todo) => ({ _httpResponseCode: todo.status })));
  }

  isLoggedIn() {
    return !!this.getId();
  }
  getId() {
    return localStorage.getItem('userIdRole');
  }

  passwordReset(password: string, token: string) {
    return this.api.post(`passwordReset/${token}`, { password });
  }

  validateInvitation(password: string, token: string) {
    return this.api.post(`validateInvitation/${token}`, { password });
  }

  private doLoginUser(tokens: Tokens) {
    localStorage.setItem(
      'userIdRole',
      JSON.stringify({
        id: tokens.id,
        name: tokens.name,
        surname: tokens.surname,
        isAdmin: tokens.isAdmin
      })
    );
  }
}
