// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  type: 'DEVELOPMENT',
  apiUrl: 'https://admin-fincuro-maketplace.eastus.cloudapp.azure.com',
  appKey: 'ece781cb-c312-4fb8-a729-63445f8980fd',
  apiMap: 'AIzaSyA_xecrOwhe0xKrjHOvWZ4aL4qYJ3hxPEs',
  algolia: {
    appId: 'HGQNKTK675',
    apiKey: '24463f0068347a090a88218d644e59d1'
  },
  defaultLanguage: {
    id: 0,
    key: 'en',
    name: 'English',
    iso: 'UK',
    isDefault: true,
    localID: 'en-US'
  },
  appLanguages: [
    {
      id: 0,
      key: 'en',
      name: 'English',
      iso: 'UK',
      isDefault: true,
      localID: 'en-UK'
    },
    {
      id: 1,
      key: 'es',
      name: 'Spanish',
      iso: 'ES',
      isDefault: true,
      localID: 'es-ES'
    },
    {
      id: 2,
      key: 'fr',
      name: 'French',
      iso: 'FR',
      isDefault: false,
      localID: 'fr-FR'
    },
    {
      id: 3,
      key: 'de',
      name: 'Germany',
      iso: 'DE',
      isDefault: false,
      localID: 'de-DE'
    }
  ],
  insights: {
    key: 'fc57f47e-5202-4965-9169-89ea04101a24'
  },
  appVersion: '1.0.4'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
